
/**
 * useFetch wrapper for this application API.
 * @param path api endpoint, for example '/product'
 * @param options query query & filter for the Directus API
 * @returns same as useFetch
 */

import { UseFetchOptions } from "#app"

// describe the response from API
interface DirectusResponse<T> {
  meta: Record<'filter_count', number>
  data: T[]
}

export const useApiFetch = <T>(
  path: string,
  query: {},
  key?: string | null,
  opts?: UseFetchOptions<DirectusResponse<T>> | undefined
) => {
  const config = useRuntimeConfig()

  return useFetch<DirectusResponse<T>>(() => `/items${path}`, {
    key: key || path,
    baseURL: config.public.apiBaseURL,
    query,
    watch: opts?.watch,
  })
}
